// import React, { useEffect, useState } from "react";
// import { faInfo } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import introJs from "intro.js";
// import MarketTooltip from "../MarketTooltip";
// import { useDispatch, useSelector } from "react-redux";
// import { setHasOnboarded } from "src/redux/slices/marketOrderSlice";
// import { RootState } from "src/redux/store";
// import MarketHomeHelp from "../help/MarketHomeHelp";
// import useHelpDrawer from "src/hooks/private/useHelpDrawer";
// import useTheme from "src/hooks/useTheme";
// import "src/css/introJs.css";

interface HelpButtonProps {
  props?: any;
}

const HelpButton = ({ props }: HelpButtonProps) => {
  // const { openHelpDrawerWith, setShowHelpDrawer } = useHelpDrawer();
  // const { mode, setMode } = useTheme();
  // const dispatch = useDispatch();

  // const hasOnboarded = useSelector(
  //   (state: RootState) => state.marketOrders.hasOnboarded
  // );
  // const addresses = useSelector(
  //   (state: RootState) => state.addresses.addresses
  // );

  // const wasInDarkMode = mode === "dark";

  // const startIntro = () => {
  //   const intro = introJs();
  //   setShowHelpDrawer(false);

  //   setMode("light");

  //   intro.setOptions({
  //     steps: [
  //       {
  //         intro: `Welcome to SendFuse Market! This is a quick tour to help you get started.`,
  //         position: "bottom",
  //       },
  //       {
  //         intro: `Start by typing here to add an address. Add as many as you'd like!`,
  //         position: "bottom",
  //         element: ".searchBarMarket",
  //       },
  //       {
  //         intro: addresses.length
  //           ? `Select an address from your list to get started. Selected addresses will be highlighted.`
  //           : `Once you've added an address, select it from the list to get started.`,
  //         position: "bottom",
  //         element: ".addressSelector",
  //       },
  //       {
  //         intro: `Choose between getting a Debt Stack, writing an Offer, or creating a Proof of Funds Letter.`,
  //         position: "bottom",
  //         element: ".featureSelector",
  //       },
  //       {
  //         intro: `This button opens the Resource Center where you can find additional information about SendFuse Market and replay this tour again whenever you need.`,
  //         position: "bottom",
  //         element: ".helpButton",
  //       },
  //     ],
  //   });

  //   intro.start();

  //   intro.onExit(() => {
  //     dispatch(setHasOnboarded(true));

  //     setMode(wasInDarkMode ? "dark" : "light");
  //   });
  // };

  // useEffect(() => {
  //   if (!hasOnboarded) {
  //     startIntro();
  //   }
  // }, [hasOnboarded]);

  // const handleClick = () => {
  //   openHelpDrawerWith({
  //     title: "SendFuse Market",
  //     body: <MarketHomeHelp startIntro={startIntro} />,
  //   });
  // };

  return (
    <></>
    // <button
    //   data-tip
    //   data-for={`tooltip-id-help-button`}
    //   className="helpButton btn btn-ghost px-[23px] hover:bg-hover-card-light hover:dark:bg-hover-card-dark"
    //   onClick={handleClick}
    // >
    //   <FontAwesomeIcon icon={faInfo} className="text-[20px] text-secondary" />
    //   <MarketTooltip
    //     id={`tooltip-id-help-button`}
    //     content={"Resource Center"}
    //   />
    // </button>
  );
};

export default HelpButton;
