import React from "react";
import useTheme from "src/hooks/useTheme";
import logoLight from "../../assets/images/SendFuse_Logo_Light.png";
import logoDark from "../../assets/images/SendFuse_Logo_Dark.png";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";

interface HeaderNavBirddogMobileProps {
  props?: any;
}

const HeaderNavBirddogMobile = ({ props }: HeaderNavBirddogMobileProps) => {
  const { mode } = useTheme();

  const { app } = useApp();

  const { logout } = useAuth();

  return (
    <>
      <header className="flex-no-wrap fixed z-10 flex min-w-full items-center justify-around bg-card-light p-3 dark:bg-card-dark">
        <div className="flex h-8 w-full items-center justify-center">
          <div className="mt-1 flex w-1/3 items-center justify-center">
            <img
              src={mode === "light" ? logoLight : logoDark}
              className=""
              alt={`${app?.name || getHostName()} Logo`}
            />
          </div>
        </div>
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="text-icon-light dark:text-icon-dark">
            <FontAwesomeIcon icon={faBars} />
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content z-[1] w-52 rounded-box bg-white dark:bg-base-100 mt-1 p-2 shadow"
          >
            <li
              onClick={() => logout()}
              className="flex cursor-pointer flex-row items-center justify-start text-icon-light dark:text-icon-dark"
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="text-md  "
              />
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default HeaderNavBirddogMobile;
