/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from "react";
import {
  faCircleCheck,
  faCircleExclamation,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import { AnimatePresence, motion } from "framer-motion";
import delay from "src/helpers/delay";
import useModal from "src/hooks/useModal";
import { propertyApi } from "src/api";
import Property from "src/interfaces/property";
import useAlert from "src/hooks/useAlert";
import useSearchBar from "src/hooks/useSearchBar";
import { getGeocode } from "use-places-autocomplete";
import { PropertyDrawerOptionsProps } from "src/contexts/private/DrawerContext";

interface IconWrapperProps {
  icon: ReactNode;
  index: number;
}

const IconWrapper = ({ icon, index }: IconWrapperProps) => {
  return (
    <motion.div
      key={index}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.1 }}
      className="flex h-[27px] w-[27px] items-center justify-center "
    >
      {icon}
    </motion.div>
  );
};

type ProcessStates = "default" | "processing" | "invalid" | "success";

// Define an object where keys correspond to ProcessStates and values are functions returning JSX
const stateComponents: Record<ProcessStates, () => ReactNode> = {
  default: () => (
    <IconWrapper icon={<FontAwesomeIcon icon={faLink} />} index={0} />
  ),
  processing: () => (
    <IconWrapper
      icon={<LoadingSpinner className={"text-secondary"} />}
      index={1}
    />
  ),
  invalid: () => (
    <IconWrapper
      icon={
        <FontAwesomeIcon icon={faCircleExclamation} className="text-warning" />
      }
      index={2}
    />
  ),
  success: () => (
    <IconWrapper
      icon={<FontAwesomeIcon icon={faCircleCheck} className="text-success" />}
      index={3}
    />
  ),
};

interface DealioLinkModalProps {
  openPropertyDrawerWith: (o: PropertyDrawerOptionsProps) => void;
}

const DealioLinkModal = ({openPropertyDrawerWith}: DealioLinkModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const { checkExistingProperty } = useSearchBar();

  const [dealioLink, setDealioLink] = useState<string>("");

  const [processState, setProcessState] = useState<ProcessStates>("default");

  const extractHashFromLink = (link: string) => {
    const regex = /property\/(.*?)\?/;
    const matches = link.match(regex);

    let fullAddress = "";
    // Extract the `leadSearch` query parameter
    const queryParams = new URLSearchParams(link.split("?")[1]);
    const leadSearch = queryParams.get("leadSearch");

    if (leadSearch) {
      // Decode and parse the `leadSearch` JSON
      const decodedLeadSearch = decodeURIComponent(leadSearch);
      const leadSearchObj = JSON.parse(decodedLeadSearch);

      // Navigate through the object to find the address
      const addressObj = leadSearchObj.filterProperties.places[0].address;
      fullAddress = addressObj ? addressObj.address : null;
    }

    return {
      hash: matches ? matches[1] : null,
      fullAddress,
    };
  };

  useEffect(() => {
    const handleChange = async () => {
      if (dealioLink.length > 0) {
        setProcessState("processing");
        const { hash, fullAddress } = extractHashFromLink(dealioLink);
        if (!hash) {
          setProcessState("invalid");
        } else {
          await delay(500);
          setProcessState("success");

          const results = await getGeocode({ address: fullAddress });

          const { place_id } = results[0];

          const result: any = await checkExistingProperty(
            fullAddress,
            place_id
          );
          if (result) {
            setShowModal(false);
            return openPropertyDrawerWith({
              property: { id: result.id } as Property,
            });
          }

          try {
            const property = await propertyApi.researchNear({
              hash,
              fullAddress,
            });
            setShowModal(false);
            return openPropertyDrawerWith({
              property: { id: property?.id } as Property,
            });
          } catch (err: any) {
            setAlert({
              display: true,
              type: "error",
              message: err?.message || "Error getting this property",
            });
          }
        }
      } else {
        setProcessState("default");
      }
    };

    handleChange();
  }, [dealioLink]);

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="w-1/2">
          <label className="input input-bordered flex items-center gap-2 bg-card-light text-zinc-500 dark:bg-back-dark">
            <AnimatePresence>{stateComponents[processState]()}</AnimatePresence>

            <input
              type="text"
              className="grow text-text-dark placeholder:text-zinc-400 dark:text-text-light"
              placeholder="Paste Dealio Link Here"
              value={dealioLink}
              onChange={(e: any) => setDealioLink(e.target.value)}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default DealioLinkModal;
