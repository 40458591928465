import { ComponentType, Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useRoutes } from "react-router-dom";

/***** Layouts *****/
import PublicLayout from "./layouts/PublicLayout";
import MarketLayout from "./layouts/MarketplaceLayout";

/***** Route Helpers *****/
import PrivateRoute from "./auth/PrivateRoute";
import PublicRoute from "./auth/PublicRoute";

/***** Guards *****/
import TokenGuard from "./contexts/TokenGuardContext";
import JoinInvitationGuard from "./guards/JoinInvitationGuard";
import DemoInvitationGuard from "./guards/DemoInvitationGuard";
import LogAsUserInvitationGuard from "./guards/LogAsUserInvitationGuard";
import UpdateCardGuard from "./guards/UpdateCardGuard";
import AffiliateGuard from "./guards/AffiliateGuard";

import useApp from "./hooks/useAppContext";
import NewSequence from "./pages/private/NewSequence";
import EditCampaign from "./components/sequences/campaigns/EditCampaign";
import Sequences from "src/pages/private/Sequences";
import NewCampaign from "./components/sequences/campaigns/NewCampaign";
import getHostName from "./helpers/getAppFromHost";
import LoginFromNative from "./guards/LoginFromNative";
import LoadingSpinner from "./components/loading/LoadingSpinner";
import RegistrationGuard from "./guards/RegistrationGuard";
import OrderSyncWrapper from "./components/market/order/OrderSyncWrapper";

export const Loader =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) =>
    (
      <Suspense
        fallback={
          <div className="absolute top-[200px] flex h-full w-full items-center justify-center md:top-0">
            <LoadingSpinner className="h-24 w-24 text-secondary" />
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    );

// Use lazy loading to improve rendering performance and the bundle size by importing component dynamically

/***** Public Pages *****/
const Page404 = Loader(lazy(() => import("src/pages/public/NotFound")));
const Unauthorized = Loader(
  lazy(() => import("src/pages/public/Unauthorized"))
);
const ExpiredTokenPage = Loader(
  lazy(() => import("src/pages/public/ExpiredToken"))
);

const Login = Loader(lazy(() => import("src/pages/public/Login")));
const Register = Loader(
  lazy(() => import("src/pages/public/registration_team/Register"))
);
const RegisterUser = Loader(
  lazy(() => import("src/pages/public/registration_user/RegisterUser"))
);
const RegisterMigration = Loader(
  lazy(
    () => import("src/pages/public/registration_migration/RegisterMigration")
  )
);
const Subscription = Loader(
  lazy(() => import("src/pages/public/registration_team/Subscription"))
);
const ForgotPassword = Loader(
  lazy(() => import("src/pages/public/recover_password/ForgotPassword"))
);
const ResetPassword = Loader(
  lazy(() => import("src/pages/public/recover_password/ResetPassword"))
);
const DashPrivacyPolicy = Loader(
  lazy(() => import("src/pages/public/DashPrivacyPolicy"))
);
const Market = Loader(lazy(() => import("src/pages/public/market/Market")));
const OrderSuccess = Loader(
  lazy(() => import("src/pages/public/market/OrderSuccess"))
);
const Order = Loader(lazy(() => import("src/pages/public/market/Order")));
const Orders = Loader(lazy(() => import("src/pages/public/market/Orders")));
const MarketSettings = Loader(
  lazy(() => import("src/pages/public/market/MarketSettings"))
);

/***** Private Pages *****/
const Dashboard = Loader(lazy(() => import("src/pages/private/Dashboard")));
const Properties = Loader(lazy(() => import("src/pages/private/Properties")));
const Team = Loader(lazy(() => import("src/pages/private/Team")));
const Settings = Loader(lazy(() => import("src/pages/private/Settings")));
const Dash = Loader(lazy(() => import("src/pages/private/Dash")));
const DashDownload = Loader(
  lazy(() => import("src/pages/private/DashDownload"))
);
const Support = Loader(lazy(() => import("src/pages/private/Support")));
const Roadmap = Loader(lazy(() => import("src/pages/private/Roadmap")));
const DriveAI = Loader(lazy(() => import("src/pages/private/DriveAI")));
const CoinHistory = Loader(
  lazy(() => import("src/pages/private/Transactions"))
);
const Notifications = Loader(
  lazy(() => import("src/pages/private/Notifications"))
);
const EditTemplate = Loader(
  lazy(() => import("src/components/templates/EditTemplate"))
);
const Watchlist = Loader(lazy(() => import("src/pages/private/Watchlist")));
const ImportHistory = Loader(
  lazy(() => import("src/pages/private/ImportHistory"))
);
const Brokers = Loader(lazy(() => import("src/pages/private/Brokers")));

const Routes = () => {
  const { app } = useApp();

  const appName = app?.name || getHostName();

  const routing = useRoutes([
    {
      path: "",
      children: [
        {
          path: "/",
          element: <Navigate to="/login" replace />,
        },
        {
          path: "/",
          element: <PublicLayout />,
          children: [
            {
              path: "privacy",
              element: (
                <>
                  <Helmet>
                    <title>
                      {app?.dashName} Privacy Policy | {appName}
                    </title>
                  </Helmet>
                  <DashPrivacyPolicy />
                </>
              ),
            },
            {
              path: "dash-privacy-policy",
              element: (
                <>
                  <Helmet>
                    <title>
                      {app?.dashName} Privacy Policy | {appName}
                    </title>
                  </Helmet>
                  <DashPrivacyPolicy />
                </>
              ),
            },
            {
              path: "404",
              element: (
                <>
                  <Helmet>
                    <title>404 Not Found | {appName}</title>
                  </Helmet>
                  <Page404 />
                </>
              ),
            },

            {
              path: "subscription",
              element: (
                <>
                  <Helmet>
                    <title>{appName || "Subscription"}</title>
                  </Helmet>
                  <Subscription />
                </>
              ),
            },
            {
              path: "401",
              element: (
                <>
                  <Helmet>
                    <title>401 Unauthorized | {appName}</title>
                  </Helmet>
                  <Unauthorized />
                </>
              ),
            },
            {
              path: "invalid-token",
              element: (
                <>
                  <Helmet>
                    <title>Invalid Token | {appName}</title>
                  </Helmet>
                  <ExpiredTokenPage />
                </>
              ),
            },
          ],
        },
        {
          path: "/market",
          element: <MarketLayout />,
          children: [
            {
              path: "",
              element: (
                <>
                  <Helmet>
                    <title>
                      {appName} Market | {appName}
                    </title>
                  </Helmet>
                  <Market />
                </>
              ),
            },
            {
              path: "/market/success/:sessionID",
              element: (
                <>
                  <Helmet>
                    <title>
                      {appName} Order Success | {appName}
                    </title>
                  </Helmet>
                  <OrderSuccess />
                </>
              ),
            },
            {
              path: "/market/settings",
              element: (
                <>
                  <Helmet>
                    <title>
                      {appName} Settings | {appName}
                    </title>
                  </Helmet>
                  <MarketSettings />
                </>
              ),
            },
            {
              path: "/market/orders",
              element: (
                <>
                  <OrderSyncWrapper>
                    <Helmet>
                      <title>
                        {appName} Orders | {appName}
                      </title>
                    </Helmet>
                    <Orders />
                  </OrderSyncWrapper>
                </>
              ),
            },
            {
              path: "/market/orders/:orderID",
              element: (
                <>
                  <OrderSyncWrapper>
                    <Helmet>
                      <title>
                        {appName} Order | {appName}
                      </title>
                    </Helmet>
                    <Order />
                  </OrderSyncWrapper>
                </>
              ),
            },
          ],
        },
        {
          path: "/",
          element: <PublicRoute />,
          children: [
            {
              path: "",
              element: <TokenGuard />,
              children: [
                {
                  path: "register",
                  element: (
                    <>
                      <Helmet>
                        <title>Register | {appName}</title>
                      </Helmet>
                      <Register />
                    </>
                  ),
                },
                {
                  path: "login",
                  element: (
                    <>
                      <Helmet>
                        <title>Login | {appName}</title>
                      </Helmet>
                      <Login />
                    </>
                  ),
                },
                {
                  path: "join/v2/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Join | {appName}</title>
                      </Helmet>
                      <JoinInvitationGuard />
                    </>
                  ),
                },
                {
                  path: "/demo/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Demo Mode | {appName}</title>
                      </Helmet>
                      <DemoInvitationGuard />
                    </>
                  ),
                },
                {
                  path: "/log-as-user/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Login as User | {appName}</title>
                      </Helmet>
                      <LogAsUserInvitationGuard />
                    </>
                  ),
                },
                {
                  path: "/login-from-native/:token/:destination",
                  element: (
                    <>
                      <Helmet>
                        <title>Login as User | {appName}</title>
                      </Helmet>
                      <LoginFromNative />
                    </>
                  ),
                },
                {
                  path: "/update-card/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Update card | {appName}</title>
                      </Helmet>
                      <UpdateCardGuard />
                    </>
                  ),
                },
              ],
            },
            {
              path: "register/:token",
              element: (
                <>
                  <Helmet>
                    <title>Register | {appName}</title>
                  </Helmet>
                  <RegisterUser />
                </>
              ),
            },
            {
              path: "register-referral/:affiliateID",
              element: (
                <>
                  <Helmet>
                    <title>Register | {appName}</title>
                  </Helmet>
                  <AffiliateGuard />
                </>
              ),
            },
            {
              path: "register-team",
              element: (
                <>
                  <Helmet>
                    <title>Register | {appName}</title>
                  </Helmet>
                  <RegistrationGuard />
                </>
              ),
            },

            {
              path: "migrate/:token",
              element: (
                <>
                  <Helmet>
                    <title>Migrate to V2 | {appName}</title>
                  </Helmet>
                  <RegisterMigration />
                </>
              ),
            },
            {
              path: "forgot-password",
              element: (
                <>
                  <Helmet>
                    <title>Forgot Password | {appName}</title>
                  </Helmet>
                  <ForgotPassword />
                </>
              ),
            },
            {
              path: "reset-password/:token",
              element: (
                <>
                  <Helmet>
                    <title>Reset Password | {appName}</title>
                  </Helmet>
                  <ResetPassword />
                </>
              ),
            },
          ],
        },
        {
          path: "/",
          element: <PrivateRoute />,
          children: [
            {
              path: "dashboard",
              element: (
                <>
                  <Helmet>
                    <title>Dashboard | {appName}</title>
                  </Helmet>
                  <Dashboard />
                </>
              ),
            },
            {
              path: "/login-from-native/:token/:destination",
              element: (
                <>
                  <Helmet>
                    <title>Login as User | {appName}</title>
                  </Helmet>
                  <LoginFromNative />
                </>
              ),
            },
            {
              path: "properties",
              element: (
                <>
                  <Helmet>
                    <title>Properties | {appName}</title>
                  </Helmet>
                  <Properties />
                </>
              ),
            },
            {
              path: "watchlist",
              element: (
                <>
                  <Helmet>
                    <title>Watchlist | {appName}</title>
                  </Helmet>
                  <Watchlist />
                </>
              ),
            },
            {
              path: "import-history",
              element: (
                <>
                  <Helmet>
                    <title>Import History | {appName}</title>
                  </Helmet>
                  <ImportHistory />
                </>
              ),
            },
            {
              path: "sequences",
              element: (
                <>
                  <Helmet>
                    <title>Sequences | {appName}</title>
                  </Helmet>
                  <Sequences />
                </>
              ),
            },
            {
              path: "sequences/postcards",
              element: (
                <>
                  <Helmet>
                    <title>Sequences | {appName}</title>
                  </Helmet>
                  <Sequences activeTab={"postcards"} />
                </>
              ),
            },
            {
              path: "sequences/letters",
              element: (
                <>
                  <Helmet>
                    <title>Sequences | {appName}</title>
                  </Helmet>
                  <Sequences activeTab={"letters"} />
                </>
              ),
            },
            {
              path: "sequences/campaigns",
              element: (
                <>
                  <Helmet>
                    <title>Sequences | {appName}</title>
                  </Helmet>
                  <Sequences activeTab={"campaigns"} />
                </>
              ),
            },
            {
              path: "sequences/campaigns/new",
              element: (
                <>
                  <Helmet>
                    <title>New Campaign | {appName}</title>
                  </Helmet>
                  <NewCampaign />
                </>
              ),
            },
            {
              path: "sequences/new/:propertyID",
              element: (
                <>
                  <Helmet>
                    <title>New Sequences | {appName}</title>
                  </Helmet>
                  <NewSequence />
                </>
              ),
            },
            {
              path: "sequences/campaigns/edit/:campaignID",
              element: (
                <>
                  <Helmet>
                    <title>New Sequences | {appName}</title>
                  </Helmet>
                  <EditCampaign />
                </>
              ),
            },
            {
              path: "templates/:templateID/edit",
              element: (
                <>
                  <Helmet>
                    <title>Templates | {appName}</title>
                  </Helmet>
                  <EditTemplate />
                </>
              ),
            },
            {
              path: "team",
              element: (
                <>
                  <Helmet>
                    <title>Team | {appName}</title>
                  </Helmet>
                  <Team />
                </>
              ),
            },
            {
              path: "brokers",
              element: (
                <>
                  <Helmet>
                    <title>Brokers | {appName}</title>
                  </Helmet>
                  <Brokers />
                </>
              ),
            },
            {
              path: `dash-download`,
              element: (
                <>
                  <Helmet>
                    <title>
                      Download Dash
                      {/* {appName} {app?.dashName} | {appName} */}
                    </title>
                  </Helmet>
                  <DashDownload />
                </>
              ),
            },
            {
              path: `fetch-download`,
              element: (
                <>
                  <Helmet>
                    <title>
                      Download Fetch
                      {/* {appName} {app?.dashName} | {appName} */}
                    </title>
                  </Helmet>
                  <DashDownload />
                </>
              ),
            },
            {
              path: `dash-download`,
              element: (
                <>
                  <Helmet>
                    <title>
                      {appName} {app?.dashName} | {appName}
                    </title>
                  </Helmet>
                  <DashDownload />
                </>
              ),
            },
            {
              path: `dash`,
              element: (
                <>
                  <Helmet>
                    <title>{/* {appName} {app?.dashName} | {appName} */}</title>
                  </Helmet>
                  <Dash />
                </>
              ),
            },
            {
              path: "DriveAI",
              element: (
                <>
                  <Helmet>
                    <title>New Drive | {appName}</title>
                  </Helmet>
                  <DriveAI />
                </>
              ),
            },
            {
              path: "coinhistory",
              element: (
                <>
                  <Helmet>
                    <title>Coin History | {appName}</title>
                  </Helmet>
                  <CoinHistory />
                </>
              ),
            },
            {
              path: "settings",
              element: (
                <>
                  <Helmet>
                    <title>Settings | {appName}</title>
                  </Helmet>
                  <Settings />
                </>
              ),
            },
            {
              path: "support",
              element: (
                <>
                  <Helmet>
                    <title>Support | {appName}</title>
                  </Helmet>
                  <Support />
                </>
              ),
            },
            {
              path: "roadmap",
              element: (
                <>
                  <Helmet>
                    <title>Roadmap | {appName}</title>
                  </Helmet>
                  <Roadmap />
                </>
              ),
            },
            {
              path: "notifications",
              element: (
                <>
                  <Helmet>
                    <title>Notifications | {appName}</title>
                  </Helmet>
                  <Notifications />
                </>
              ),
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to="/404" replace />,
        },
      ],
    },
  ]);

  // if (!appLoaded) {
  //   console.log("App Not Loaded yet")
  //   return (
  //     <div className="absolute top-[200px] flex h-full w-full items-center justify-center md:top-0">
  //       <LoadingSpinner className="h-24 w-24 text-secondary" />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>{app?.name || window.location.host}</title>
      </Helmet>
      {routing}
    </>
  );
};

export default Routes;
