import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import CallsEmailsHelp from "./CallsEmailsHelp";
import FuseCoinsHelp from "./FuseCoinsHelp";
import OffersHelp from "./OffersHelp";
import ResearchingHelp from "./ResearchingHelp";
import SequencesHelp from "./SequencesHelp";
import SkipTracesHelp from "./SkipTracesHelp";
import TeamHelp from "./TeamHelp";
import DebtStacksHelp from "./DebtStacksHelp";
import { useNavigate } from "react-router-dom";
import StaticActionBadge from "../badges/StaticActionBadge";
import WatchlistHelp from "./WatchlistHelp";
import useApp from "src/hooks/useAppContext";
import CampaignsHelp from "./CampaignsHelp";
import BulkImportHelp from "./BulkImportHelp";
import getHostName from "src/helpers/getAppFromHost";
import ProofOfFundsLetterHelp from "./ProofOfFundsLetterHelp";
// import MigratingHelp from "./MigratingHelp";
// import AIScoresHelp from "./SendFuseDashHelp";

const HomeHelp = () => {
  const { setShowHelpDrawer, helpLink } = useHelpDrawer();

  const { app } = useApp();

  const navigate = useNavigate();

  const handleSupportTicketClick = () => {
    setShowHelpDrawer(false);
    navigate("/support");
  };

  return (
    <>
      <div>
        <p>
          To learn about the different tools available to you in{" "}
          {app?.name || getHostName()} click on any of the topics below.
        </p>
      </div>
      <br></br>
      <div className="ml-4 text-lg">
        <ul className="">
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Research"}
              onClick={() => helpLink("Researching", <ResearchingHelp />)}
            />
          </li>

          <li className="mb-1.5">
            <StaticActionBadge
              title={"Debt Stack"}
              onClick={() => helpLink("Debt Stacks", <DebtStacksHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Skip Trace"}
              onClick={() => helpLink("Skip Traces", <SkipTracesHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Sequence"}
              onClick={() => helpLink("Sequences", <SequencesHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Campaign"}
              onClick={() => helpLink("Campaigns", <CampaignsHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Calls/Emails"}
              onClick={() => helpLink("Calls/Emails", <CallsEmailsHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Offer"}
              onClick={() => helpLink("Offers", <OffersHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Watchlist"}
              onClick={() => helpLink("Watchlist", <WatchlistHelp />)}
            />
          </li>
          <li className="mb-1.5">
            <StaticActionBadge
              title={"Proof Of Funds"}
              onClick={() =>
                helpLink("Proof Of Funds Letters", <ProofOfFundsLetterHelp />)
              }
            />
          </li>
        </ul>
      </div>
      <br></br>
      <div>
        <span>Other topics that may be helpful:</span>
      </div>
      <br></br>
      <div className="ml-4">
        <ul className="list-disc">
          <li
            className="cursor-pointer font-bold hover:underline"
            onClick={() =>
              helpLink(app?.coinName || "FuseCoins", <FuseCoinsHelp />)
            }
          >
            <span className="text-primary">{app?.coinName}</span>
          </li>

          {/* <li
            className="cursor-pointer font-bold hover:underline"
            onClick={() => helpLink("Subscriptions", <SubscriptionsHelp />)}
          >
            <span className="text-primary">Subscriptions</span>
          </li> */}

          <li
            className="cursor-pointer font-bold hover:underline"
            onClick={() => helpLink("Team", <TeamHelp />)}
          >
            <span className="text-primary">Team</span>
          </li>
          {/* <li
            className="cursor-pointer font-bold hover:underline"
            onClick={() => helpLink("Download To Mobile", <MobileHelp />)}
          >
            <span className="text-primary">Download To Mobile</span>
          </li> */}
          <li
            className="cursor-pointer font-bold hover:underline"
            onClick={() => helpLink("Bulk Import", <BulkImportHelp />)}
          >
            <span className="text-primary">Bulk Import</span>
          </li>
        </ul>
      </div>
      <br></br>
      <div>
        <span>
          <span>
            {"Still need help getting your question answered? Feel free to "}
          </span>
          <span className="cursor-pointer font-bold text-primary hover:underline">
            <a
              href={`mailto:${app?.supportEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </a>
          </span>
          {" or open a "}
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={handleSupportTicketClick}
          >
            support ticket
          </span>
          .
        </span>
      </div>
    </>
  );
};

export default HomeHelp;
