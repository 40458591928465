import React from "react";
import useApp from "src/hooks/useAppContext";

interface FuseCoinProps {
  props?: any;
}

const FuseCoin = ({ props }: FuseCoinProps) => {

  const { app } = useApp();

  return (
    <>
      <div className="h-[26px] w-[26px] rounded-full bg-[#F59E0B] ">
        <div className="relative flex items-center justify-center">
          <span className="dark:text-text-black z-20 -rotate-12 font-extrabold text-text-dark">
            {app?.coinName.replace(/[^A-Z]+/g, "")}
          </span>
          <div className="">
            <span className="dark:text-text-black absolute -top-[4px] left-[7px] z-0 -rotate-12 font-black text-text-dark">
              ||
            </span>
            <span className="dark:text-text-black absolute left-[8px] top-[2px] z-0 -rotate-12 font-black text-text-dark">
              ||
            </span>
            <span className="dark:text-text-black absolute left-[4px] top-[5.5px] z-10 h-[14.5px] w-4 -rotate-12 font-black text-text-dark bg-[#F59E0B]">
              
            </span>

          </div>
        </div>
      </div>
    </>
  );
};

export default FuseCoin;
