// import React, { useEffect } from "react";
// import { useTrackingCode } from "react-hubspot-tracking-code-hook";
// import { useLocation } from "react-router-dom";

const HubSpotTracker: React.FC = () => {
  // const { setPathPageView, setTrackPageView } = useTrackingCode();
  // const location = useLocation();

  // useEffect(() => {
  //   setPathPageView(location.pathname);
  //   setTrackPageView();
  // }, [location.pathname, setPathPageView, setTrackPageView]);

  return null;
};

export default HubSpotTracker;
